import * as React from "react";
import { Link } from "gatsby";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logoUnifiers from "../images/logoUnifiers.png";
import { useLocation } from "@reach/router";
const HeaderInternational = ({ siteTitle }) => {
  const location = useLocation();
  return (
    <>
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        className="custom-container-navbar sticky-top"
      >
        <Navbar.Brand href="/">
          <img src={logoUnifiers} className="img-fluid" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          className="justify-content-end"
        >
          <Nav className="ml-auto">
            <Nav.Link href="#about">About</Nav.Link>

            <Nav.Link href="#current-opening">Current Opening</Nav.Link>

            <Nav.Link href="#affiliation">Affiliations</Nav.Link>

            <Nav.Link href="#employer">Employers</Nav.Link>
            <Nav.Link href="#services">Services</Nav.Link>
            <Nav.Link href="#team">team</Nav.Link>
            <Nav.Link href="#footer-international">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
};

export default HeaderInternational;
