import currentOpening from "../images/international/CURRENT-OPENING.png";
import services from "../images/international/services.png";
import services1 from "../images/international/services1.png";
import services2 from "../images/international/services2.png";
import services3 from "../images/international/services3.png";
import services4 from "../images/international/services4.png";
import germany from "../images/GermanyFyer_page.jpeg";
// import australia from "../images/AustraliaFlyer_page.jpg";
import germanyEmployer from "../images/international/careGiver.jpg";
import australiaEmployer from "../images/international/automobile.jpg";
import australiaSKilledMIgrationLogo from "../images/international/ASM.png";
import nocnLogo from "../images/international/nocn.png";
import wallAndFloor from "../images/wallAndFloor.jpeg";
import australia from "../images/automotive.jpeg";
import automotiveMotor from "../images/automotiveMotor.jpeg";

export const curentOpening = [
  {
    img_url: currentOpening,
    heading: "SkillsTrainer",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    button_name: "APPLY NOW",
    button_url: "",
  },
  {
    img_url: currentOpening,
    heading: "SkillsTrainer",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    button_name: "APPLY NOW",
    button_url: "",
  },
  {
    img_url: currentOpening,
    heading: "SkillsTrainer",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    button_name: "APPLY NOW",
    button_url: "",
  },
  {
    img_url: currentOpening,
    heading: "SkillsTrainer",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
    button_name: "APPLY NOW",
    button_url: "",
  },
];

export const servicesData = [
  {
    img_url: services,
    heading: "Overseas Job <br> Consultancy",
    className: "div-overlay",
  },
  {
    img_url: services2,
    heading: "Skilled Visa & <br>Work Permit",
    className: "div-overlay",
  },
  {
    img_url: services3,
    heading: "Pre-Departure Orientation & <br>Cultural Adaptation Training",
    className: "div-overlay",
  },
  {
    img_url: services4,
    heading:
      "Language & <br>Interview Preparation Training <br> English & German",
    className: "div-overlay",
  },
];

export const whatsNew = [
  {
    img_url: germany,
    text: "Register",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSeQPzpxHrPXRIpfowO5lI-Z7MriaAKmrMO9850zOIYMKyCGVg/viewform",
  },
  {
    img_url: australia,
    text: "Register",
    link: "https://docs.google.com/forms/d/e/1FAIpQLScPaUDu05OnwY57PIg9K1MyHQSIgw0nG7263jgDb54hr27IAQ/viewform",
  },
  {
    img_url: wallAndFloor,
    text: "Register",
    link: "",
  },
  {
    img_url: automotiveMotor,
    text: "Register",
    link: "",
  },
];

export const Employer = [
  {
    img_url: germanyEmployer,
    text: "Germany - Care Givers & Hospitals",
  },
  {
    img_url: australiaEmployer,
    text: "Australia - Automobile Dealers",
  },
];

export const Affiliation = [
  {
    img_url: australiaSKilledMIgrationLogo,
    text: "Everyone who wants to make Australia home deserves to be supported by the most experienced and reliable migration partner who can take care of your visa & immigration needs.",
  },
  {
    img_url: nocnLogo,
    text: "NOCN Group is an educational charity whose core aims are to help learners reach their potential and organisations thrive. The group includes business units specialising in regulated UK and international qualifications, End Point Assessment, assured short courses, SMART job cards, assessment services, consultancy, and research.",
  },
];
