import React, { useState } from "react";
import HeaderInternational from "../components/header-international";
import HeroImg from "../images/international/hero.png";
import Carousel from "react-multi-carousel";
import Countries from "../images/international/Countries.png";
import {
  servicesData,
  whatsNew,
  Employer,
  Affiliation,
} from "../data/international-data";
import InternationalFooter from "../components/international-footer";
import InternationalTeam from "./international-team";

export default function Hero() {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 4,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const responsiveEmployer = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <HeaderInternational />
      <div className="row mt-4">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <div className="custom-container-international">
            <div className="pb-5">
              <div className="row align-items-center justify-content-center py-3">
                <div className="pt-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                  <div className="row">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8">
                      <h1
                        className="hero-setion-international"
                        style={{ lineHeight: "131%" }}
                      >
                        Your pathway to <br />
                        success starts with <br />
                        <span className="common-color">
                          {" "}
                          Unifiers International
                        </span>
                      </h1>
                      <a
                        className="btn btn-primary mt-4 text-start"
                        target="_blank"
                        href={whatsNew[0].link}
                        style={{ background: "#75a55c" }}
                      >
                        <h6>APPLY NOW FOR JOBS NURSES @ GERMANY</h6>
                      </a>
                      <br />
                      <a
                        className="btn btn-primary mt-4 text-start"
                        target="_blank"
                        href={whatsNew[1].link}
                        style={{ background: "#75a55c" }}
                      >
                        <h6>
                          APPLY NOW FOR JOBS AUTO DENTERS & PAINTERS @ AUSTRALIA
                        </h6>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="pt-4 col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 text-lg-end text-md-center text-sm-center text-center">
                  <img
                    className="img-fluid hero-img"
                    width={"80%"}
                    src={HeroImg}
                  />
                </div>
              </div>
            </div>

            {/* ================================== ABOUT US =================== */}
            <div id="about" className="pb-5">
              <div className="pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5">
                <h1 className="heading text-center">About Us</h1>
                <div className="text-center ">
                  Unifiers International, a subsidiary of Unifiers Social
                  Ventures Private Limited is based out in Delhi. We are the
                  Counselling and Training Partner of leading International
                  Companies in Australia, Germany, India & Middle East, etc…
                </div>
              </div>
            </div>

            {/* ======================= Current Openings ============================== */}
            <div
              className="pb-5 pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5"
              id="current-opening"
            >
              <h1 className="heading text-center">Current Openings</h1>
              <Carousel
                responsive={responsive}
                arrows={true}
                showDots={false}
                infinite={true}
                autoPlay={false}
                className="justify-content-lg-center justify-content-md-center"
              >
                {whatsNew.map((data) => {
                  return (
                    <div className="p-3">
                      <a href={data.link} target="_blank">
                        <div className="image-container">
                          <img
                            src={data.img_url}
                            className="img-overlay img-fluid"
                          />
                        </div>
                        <div className="link-overlay">{data.text}</div>
                      </a>
                      {/* <div className="">
                                                <div className="">
                                                    <img src={data.img_url} className="img-fluid" />
                                                </div>
                                            </div> */}
                      {/* <h6 className="mb-5 px-3 text-center caption-text">
                                                {data.caption}
                                            </h6> */}
                    </div>
                  );
                })}
              </Carousel>
            </div>

            {/* ======================= Affiliation ============================== */}
            <div
              className="pb-5 pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5"
              id="affiliation"
            >
              <h1 className="heading text-center">Affiliation</h1>
              <Carousel
                responsive={responsiveEmployer}
                arrows={false}
                showDots={false}
                infinite={false}
                autoPlay={false}
                className="justify-content-lg-center justify-content-md-center"
              >
                {Affiliation.map((data) => {
                  return (
                    <div className="p-3">
                      <a href={data.link} target="_blank">
                        <div>
                          <img
                            src={data.img_url}
                            width="60%"
                            className="rounded img-fluid"
                          />
                        </div>
                        <div className="">{data.text}</div>
                      </a>
                      {/* <div className="">
                                                <div className="">
                                                    <img src={data.img_url} className="img-fluid" />
                                                </div>
                                            </div> */}
                      {/* <h6 className="mb-5 px-3 text-center caption-text">
                                                {data.caption}
                                            </h6> */}
                    </div>
                  );
                })}
              </Carousel>
            </div>
            {/* ======================= Employer ============================== */}
            <div
              className="pb-5 pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5"
              id="employer"
            >
              <h1 className="heading text-center">Employers</h1>
              <Carousel
                responsive={responsiveEmployer}
                arrows={false}
                showDots={false}
                infinite={false}
                autoPlay={false}
                className="justify-content-lg-center justify-content-md-center"
              >
                {Employer.map((data) => {
                  return (
                    <div className="p-3">
                      <a href={data.link} target="_blank">
                        <div>
                          <img
                            src={data.img_url}
                            className="rounded img-fluid"
                          />
                        </div>
                        <div className="fw-bold">{data.text}</div>
                      </a>
                      {/* <div className="">
                                                <div className="">
                                                    <img src={data.img_url} className="img-fluid" />
                                                </div>
                                            </div> */}
                      {/* <h6 className="mb-5 px-3 text-center caption-text">
                                                {data.caption}
                                            </h6> */}
                    </div>
                  );
                })}
              </Carousel>
            </div>

            {/* ================================== SERVICES =================== */}

            <div className="pb-5 pt-lg-5" id="services">
              <h1 className="heading text-center">Services</h1>
              <div className="row text-center">
                {servicesData.map((data) => {
                  const lines = data.heading
                    .split("<br>")
                    .map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        {index !== data.heading.length - 1 && <br />}
                      </React.Fragment>
                    ));

                  return (
                    <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6 mt-lg-5 py-2">
                      <div className="responsive-div">
                        <img
                          className="background-image img-fluid"
                          src={data.img_url}
                          alt="Background Image"
                        />
                        <div className={data.className}>{lines}</div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            {/* ================================== COUNTRIES =================== */}

            <div
              className="pb-5 pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5"
              id="internationalPlacements"
            >
              <div
                className="pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5"
                id="education"
              >
                <h1 className="heading text-center">Countries</h1>
                <div className="row text-center">
                  <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 ">
                    <img src={Countries} width="80%" className="img-fluid" />
                  </div>
                </div>
              </div>
            </div>

            {/* =================== CURRENT OPENINGS ================================ */}

            {/* <div className="pt-0 pt-sm-0 pt-md-0 pt-lg-5 pt-xl-5">
                            <h1 className="heading text-center">Current Openings</h1>
                            <div className="row justify-content-center">
                                {
                                    curentOpening.map((data) => {
                                        return (
                                            <div className="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6 mt-3">
                                                <div className="card border-0">
                                                    <img className="card-img-top" src={data.img_url} alt="Card image cap" />
                                                    <div className="card-body">
                                                        <h5 className="card-title">{data.heading}</h5>
                                                        <p className="card-text">{data.para}</p>
                                                        <a href={data.button_url} className="btn btn-primary">
                                                            {data.button_name}
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div> */}
          </div>
          <InternationalTeam />
        </div>
        <div className="col-md-1"></div>
      </div>

      <InternationalFooter />
    </>
  );
}
